import React, { useEffect, useRef, useState } from 'react'
import './LocationReviews.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useLocation, useNavigate } from 'react-router-dom';
import Google from '../../Images/Google.png'
import book from '../../Images/book.png'
import telephone from '../../Images/telephone.png'
import Review from '../../Images/Review.png'
import locationpin from '../../Images/locationpin.png'
import LocationReviewsData from './LocationReviewsData'
import LocationData from '../LocationAll/LocationData'
import GoogleMapReact from "google-map-react";

//

export default function LocationReviews() {
    const location = useLocation()
    const mapRef = useRef(null);
    const [LoactionCity, setLoactionCity] = useState(LocationData.find(a => a.City.replace(' ', '').toLocaleLowerCase() == location.pathname.split('/')[2]).City);
    const [markers, setMarkers] = useState([]);
    const [latLongArrat, setLatLongArrat] = useState([
        {
            "position": {
                "lat": 33.8365932,
                "lng": -117.9143012
            },
            "title": "Anaheim"
        },
        {
            "position": {
                "lat": 34.1539731,
                "lng": -118.6473801
            },
            "title": "Calabasas"
        },
        {
            "position": {
                "lat": 33.8752935,
                "lng": -117.5664384
            },
            "title": "Corona"
        },
        {
            "position": {
                "lat": 34.0922335,
                "lng": -117.435048
            },
            "title": "Fontana"
        },
        {
            "position": {
                "lat": 34.1425078,
                "lng": -118.255075
            },
            "title": "Glendale"
        },
        {
            "position": {
                "lat": 33.7700504,
                "lng": -118.1937395
            },
            "title": "Long Beach"
        },
        {
            "position": {
                "lat": 33.9424658,
                "lng": -117.2296717
            },
            "title": "Moreno Valley"
        },
        {
            "position": {
                "lat": 34.1975048,
                "lng": -119.1770516
            },
            "title": "Oxnard"
        },
        {
            "position": {
                "lat": 34.1477849,
                "lng": -118.1445155
            },
            "title": "Pasadena"
        },
        {
            "position": {
                "lat": 34.055103,
                "lng": -117.7499909
            },
            "title": "Pomona"
        },
        {
            "position": {
                "lat": 34.10639889999999,
                "lng": -117.5931084
            },
            "title": "Rancho Cucamonga"
        },
        {
            "position": {
                "lat": 34.0555693,
                "lng": -117.1825381
            },
            "title": "Redlands"
        },
        {
            "position": {
                "lat": 34.1064001,
                "lng": -117.3703235
            },
            "title": "Corona"
        },
        {
            "position": {
                "lat": 33.9806005,
                "lng": -117.3754942
            },
            "title": "Corona"
        },
        {
            "position": {
                "lat": 34.1083449,
                "lng": -117.2897652
            },
            "title": "San Bernardino"
        },
        {
            "position": {
                "lat": 33.7454725,
                "lng": -117.867653
            },
            "title": "Santa Ana"
        },
        {
            "position": {
                "lat": 33.4934243,
                "lng": -117.1488156
            },
            "title": "Temecula"
        },
        {
            "position": {
                "lat": 34.1761133,
                "lng": -118.8487793
            },
            "title": "Thousand Oaks"
        },
        {
            "position": {
                "lat": 33.8358492,
                "lng": -118.3406288
            },
            "title": "Torrance"
        },
        {
            "position": {
                "lat": 34.2804923,
                "lng": -119.2945199
            },
            "title": "Ventura"
        }
    ]);
    const lenth = LocationReviewsData.filter(a => a.location == LoactionCity).length
    const avg = LocationReviewsData.filter(a => a.location == LoactionCity).reduce((a, b) => a += b.review_rate, 0);
    console.log(avg / lenth);

    return (
        <div className="LocationReviews">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>{LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Chimney Sweep Reviews | {(avg / lenth).toFixed(2) + " Stars Based on " + LocationReviewsData.filter(a => a.location == LoactionCity).length + " Reviews"} | Experts Corona Chimney Sweep</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content={`${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Chimney Sweep Reviews | ${(avg / lenth).toFixed(2) + " Stars Based on " + LocationReviewsData.filter(a => a.location == LoactionCity).length + " Reviews"} | Experts Corona Chimney Sweep`} data-react-helmet="true" />
                <meta name="og:title" property="og:title" content={`${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Chimney Sweep Reviews | ${(avg / lenth).toFixed(2) + " Stars Based on " + LocationReviewsData.filter(a => a.location == LoactionCity).length + " Reviews"} | Experts Corona Chimney Sweep`} data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content={`https://www.coronachimneysweep.org/reviews/${location.pathname.split('/')[2]}`} data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content={`Discover Our Reviews At ${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Chimney Sweep Location | ${(avg / lenth).toFixed(2) + " Stars Based on " + LocationReviewsData.filter(a => a.location == LoactionCity).length + " Reviews"} Chimney Sweep Repair in ${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)}, CA area and surrounding cities.`} data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content={`Discover Our Reviews At ${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Chimney Sweep Location | ${(avg / lenth).toFixed(2) + " Stars Based on " + LocationReviewsData.filter(a => a.location == LoactionCity).length + " Reviews"} Chimney Sweep Repair in ${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)}, CA area and surrounding cities.`} data-react-helmet="true" />
                <meta name="keywords" content="chimney sweep, chimney Inspection, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, chimney services, chimney service, Chimney Inspection, Fireplace Inspection, Fireplace Cleaning, Chimney Cleaning, Chimney Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Chimney Insulation, Chimney Installation, Chimney Liner Repair, Chimney Pointing, Chimney Rain Cap Installation, Chimney Repair, Chimney Crown Repair, Chimney Restoration, Chimney Flue Installation, Chimney Flue Repair, ​Chimney Construction, Chimney Vent Installation, Chimney Flashing, Flexible Chimney Liner Installation, Chimney Damper Repair, Chimney Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content={`Corona Chimney Sweep`} data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>See what customers are saying about {LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Chimney Sweep in your area:</h1>
                    <h2>Welcome to {LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)}'s Premier Chimney Sweep Service Provider - Reviews Page</h2>
                    <span>
                        <a href="tel:951-355-2481">CALL 951-355-2481<img src={telephone} alt="" /></a>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </span>
                    <div className="GoogleButt">
                        <img src={Google} alt={`${LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Chimney Sweep Reviews`} />
                        <h6>{(avg / lenth).toFixed(2)} Stars - Based on {lenth} Reviews For {LoactionCity.charAt(0).toUpperCase() + LoactionCity.slice(1)} Chimney Sweep Repair</h6>
                        <a href="/locations">Read Other Locations Reviews</a>
                    </div>
                </div>

            </div>

            <div className="LocationReviewsListMap">
                <div className="LocationReviewsLsit">
                    {LocationReviewsData.filter(a => a.location.toLowerCase() == LoactionCity.toLocaleLowerCase()).map((A, index) =>
                        <div key={index} className="LocationReviewsBox">
                            <div className="LocationReviewsBoxHeader">
                                <div className="LocationReviewsBoxHeaderBox">
                                    <h1>{A.service_type}</h1>
                                    <h2>{A.location}, CA</h2>
                                </div>
                                <div className="LocationReviewsBoxHeaderBox">
                                    <h1>⭐⭐⭐⭐⭐</h1>
                                    <h2>{A.date}</h2>
                                </div>
                            </div>

                            <div className="LocationReviewsBoxLeft">
                                <h3>Open Now 24/7 • Free Estimates • Same Day Service</h3>
                                <h2>Review Rated {A.review_rate} By {A.customer_name}</h2>
                                <h4>{A.review}</h4>
                                <div className="LocationReviewsBoxLeftBox">
                                    <div className="LocationReviewsBoxLeftBoxBox">
                                        <h5>Service Provided:</h5>
                                        <h6>{A.service_type}</h6>
                                    </div>
                                    <div className="LocationReviewsBoxLeftBoxBox">
                                        <h5>Technician</h5>
                                        <h6>{A.tech_name}</h6>
                                    </div>
                                    <div className="LocationReviewsBoxLeftBoxBox">
                                        <h5>More Details About This Service/Location</h5>
                                        <h6>Chimney Sweep <a href={`${LocationData.find(a => a.City == A.location).Url}`}>{A.location}, CA</a> Providing  <a href={`${LocationData.find(a => a.City == A.location).Url + `/service/${A.service_type.replaceAll(' ', '-')}`}`}>{A.service_type}</a></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {latLongArrat && latLongArrat.length ? LocationData.map((A, index) =>
                        <div key={index} className="Header1BoxMenuBox">
                            <div className="LocationAllBox">
                                <div className="LocationAllBoxLeft">
                                    <h2>{A.City}, {A.State}</h2>
                                    <h3>Open Now 24/7 • Free Estimates • Same Day Service</h3>
                                    <h4>Serving Chimney Sweep in {A.City}, CA area and surrounding cities.</h4>
                                    <h5><a href={A.Url} target='_blank'>VISIT THIS LOCATION</a> - <a href={`${A.Url}/services`} target='_blank'>SEE SERVICES</a></h5>
                                    <span>
                                        <a href={`tel:${A.Phone}`}>
                                            <img src={[telephone]} alt={`${A.Phone} - ${A.City} Chimney Sweep`} />{A.Phone}
                                        </a>

                                        <a href={`${A.Url}/book`}>
                                            <img src={[book]} alt={`SCHEDULE ONLINE ${A.City} Chimney Sweep`} />SCHEDULE ONLINE
                                        </a>

                                        <a href={`/reviews/${A.City.replace(' ', '').toLowerCase()}`} >
                                            <img src={[locationpin]} alt={`Read reviews of ${A.City} Chimney Sweep`} />READ REVIEWS
                                        </a>
                                    </span>
                                </div>
                                <div className="LocationAllBoxRight">
                                    <div className="Mapsss">
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: 'AIzaSyAqdgsErGbUqnP1mYicv7QLBFpGiF6osMY' }}
                                            defaultCenter={latLongArrat ? latLongArrat[index].position : null}
                                            defaultZoom={10}
                                            yesIWantToUseGoogleMapApiInternals
                                            onGoogleApiLoaded={({ map, maps, location }) => {
                                                mapRef.current = map;
                                                new maps.Marker({
                                                    "position": latLongArrat ? latLongArrat[index].position : null,
                                                    "title": latLongArrat ? latLongArrat[index].title : null,
                                                    "map": map,
                                                });
                                                setMarkers([...markers]);
                                            }}
                                        >
                                        </GoogleMapReact>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <NavigatorPath />
        </div>
    )
}

